<template>
  <div>
    <v-row>
      <v-col md="12" sm="12" lg="12" class="pa-10 pb-1">
        <v-card elevation="1" class="containerbox">
          <breadcrumbComp class="hidden-sm-and-down" :mainPage="mainPage" :subPage="subPage" :backSlash="backSlash"
            :pageUrl="pageUrl" :refresh="refresh" :resetFunction="resetFunction" :showAdd="showAdd" @addFav="addFav()" />
          <breadcrumbComp class="hidden-md-and-up" :subPage="subPage" :pageUrl="pageUrl" :refresh="refresh"
            :resetFunction="resetFunction" :showAdd="showAdd" @addFav="addFav()" />
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col md="12" sm="12" lg="12" class="pa-10 py-0">
        <v-card elevation="1" class="containerbox">
          <v-card-text class="pt-5">
            <v-form ref="CosmeticForm" v-model="cosmeticForm" lazy-validation v-on:submit.prevent>
              <v-row>
                <v-col cols="12" sm="12" md="12" lg="4" class="px-5 py-4 pb-2">
                  <v-text-field color="primarytext" :disabled="unitDisable" required v-model="unit"
                    label="Unit Identifier" @keydown.enter="cosmeticSearch()" outlined clearable dense class="ma-0"
                    :rules="max20Rule" @input="unit=unit?.toUpperCase()" persistent-placeholder>
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="12" lg="1" class="px-5 py-4 pb-2">
                  <v-btn color="primarytext" :disabled="searchCos" outlined class="btn-style" elevation="1"
                    @click="cosmeticSearch()">
                    <v-icon dark left> mdi-magnify </v-icon> Search
                  </v-btn>
                </v-col>
                <v-col cols="12" sm="12" md="12" lg="3" class="px-5 py-4 pb-2"></v-col>
                <v-col cols="12" sm="12" md="12" lg="4" class="px-5 py-4 pb-2 text-right">
                  <PrinterDetails />
                </v-col>
              </v-row>
            </v-form>
            <v-divder></v-divder>
            <v-row v-if="showCurrent">
              <v-col cols="12" sm="12" md="12" lg="12" class="px-5 py-4 pb-2">
                <span class="primarytext--text font-weight-bold">
                  Current Cosmetic Grade :
                </span>
                &nbsp;
                <span class="secondary--text font-weight-bold">
                  {{ currentGrade }}
                </span>
              </v-col>
            </v-row>
            <v-row v-if="showGrade">
              <v-col cols="12" sm="12" md="12" lg="4" class="px-5 py-4 pb-2">
                <v-autocomplete color="primarytext" attach outlined class="ma-0" v-model="selectedGrade" dense
                  :rules="required" :items="gradeList" label=" New Grade" item-text="DisplayString" item-value="code"
                  required @change="onGradeChange(selectedGrade)" persistent-placeholder>
                </v-autocomplete>
              </v-col>
              <v-col cols="12" v-if="showReason" sm="12" md="12" lg="4" class="px-5 py-4 pb-2">
                <v-autocomplete color="primarytext" attach outlined class="ma-0" v-model="selectedReason" dense
                  :items="reasonList" label="Grade Reason" item-text="description_1" item-value="failc_id"
                  @change="onReasonChange(selectedReason)" :rules="required" required persistent-placeholder>
                </v-autocomplete>
              </v-col>
              <v-col cols="12" sm="12" md="12" lg="4" class="px-5 py-4 pb-2">
                <v-btn :disabled="!showSubmit" color="primarytext" outlined class="btn-style" elevation="1"
                  @click="cosmeticSubmit()">
                  Submit
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import breadcrumbComp from '../common/breadcrumb-comp.vue';
import AddFavourite from "./js/addFav";
import EncryptUtility from './js/encrypt-utility.js';
import Utility from './js/utility-service.js'
import PrinterDetails from '../common/printer-details';
export default {
  data() {
    return {
      required: [(v) => !!v || 'Field is required'],
      max20Rule: [
        (v) => (v || '').length <= 20 || "Max Length of 20 character",
        (v) => !!v || 'Field is required'
      ],
      cosmeticForm: false,
      route: "",
      selectedReason: "",
      reasonList: [],
      actionList: "",
      resetValidate: false,
      mainPage: "",
      favouriteList: "",
      pageUrl: "",
      LPN: "",
      unit: "",
      subPage: "",
      mainKey: "",
      unitDisable: false,
      showSubmit: false,
      refresh: "",
      backSlash: true,
      showCurrent: false,
      binInfo: false,
      selectedGrade: "",
      validateText: true,
      showReason: false,
      reasonChange: true,
      showAdd: false,
      userId: EncryptUtility.localStorageDecrypt('userID'),
      showGrade: false,
      gradeList: [],
      searchCos: false,
      cosGrade: "",
      primaryName: EncryptUtility.localStorageDecrypt('primaryName'),
    }
  },
  async created() {
    this.route = this.$route.path.replace('/', '');
    let data = await AddFavourite.getPageAction(this.userId, this.route);
    this.actionList = data.actionList;
    this.mainPage = data.mainPage;
    this.favouriteList = data.favouriteList;
    this.pageUrl = data.pageUrl;
    this.subPage = data.subPage;
    this.showAdd = data.showAdd;
  },
  methods: {
    //on Change reason
    onReasonChange(selectedReason) {
      this.selectedReason = selectedReason
      this.showSubmit = true
    },
    //To add or remove Favourite issue
    addFav() {
      this.showAdd = !this.showAdd;
      AddFavourite.add_remove_Favourite(this.showAdd, this.userId, this.route)
    },
    // onclick search
    async cosmeticSearch() {
      let obj = {
        pass: 1,
        UserId: this.userId,
        unit: this.unit,
        main_key: 0,
        grade_n: "",
        grade_b_key: 0,
        lbl_size: "",
        printer_type: ""
      };
      let showMessage = false
      let response = await Utility.postSearch("post", obj, showMessage);
      if (Object.keys(response).length > 0) {
        this.unitDisable = true;
        this.searchCos = true;
        this.reasonList = response.Breason
        this.mainKey = response.MainKey
        if (response?.CosGrade) {
          if (response.CosGrade == "  " || response.CosGrade == "" || response.CosGrade == undefined || response.CosGrade == null) {
            this.currentGrade = "No Grade"
          } else {
            this.currentGrade = response.CosGrade
          }
          this.showCurrent = true
        }
        if (response?.Lists) {
          this.showGrade = true
          this.gradeList = response.Lists
        }
        this.cosmeticForm = false
      }
    },
    //on Click Submit
    async cosmeticSubmit() {
      if (this.currentGrade == "No Grade") {
        this.cosGrade = "";
      } else {
        this.cosGrade = this.currentGrade;
      }
      let obj = {
        pass: 2,
        UserId: this.userId,
        unit: this.unit,
        main_key: this.mainKey,
        grade_n: this.selectedGrade,
        grade_b_key: this.selectedReason,
        lbl_size: EncryptUtility.localStorageDecrypt('primaryLabelSize'),
        printer_type: EncryptUtility.localStorageDecrypt('primaryPrinterType')
      };
      let showMessage = true;
      let response = await Utility.postSearch("post", obj, showMessage);
      if (this.primaryName !== null && this.primaryName !== undefined) {
        PrinterDetails.printLabelData(response.Label, this.primaryName);
      }
      this.resetFunction();
    },
    // Reset Funtion
    resetFunction() {
      this.currentGrade = ""
      this.showCurrent = false
      this.showGrade = false
      this.mainKey = ""
      this.unit = ""
      this.showReason = false
      this.selectedReason = 0
      this.selectedGrade = ""
      this.unitDisable = false;
      this.searchCos = false;
      this.$refs.CosmeticForm.resetValidation();
    },
    // on GRade Chnage
    onGradeChange(item) {
      this.showSubmit = true
      this.selectedReason = 0
      let tempList = this.gradeList.filter(
        (x) => x.code === item
      );
      if (tempList.length > 0) {
        this.showReason = tempList[0].enable_grade_reason
        this.showReason ? this.showSubmit = false : this.showSubmit = true
      }
    }
  },
  components: {
    breadcrumbComp,
    PrinterDetails,
  },
};
</script>